import Landing from "./views/Landing";


function App() {
  return (

    <>
    <Landing/>
    </>

  );
}

export default App;
